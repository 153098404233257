.App{

}
body {
  margin: 0;
  background: darkgray;
}

.draggable-item {
  user-select: none;
  background: rgba(211, 211, 211, 0.51);
  padding: 6px;
  margin: 0 0 8px 0;
  white-space: nowrap; /* Add this line */
}
.info {
  display: inline-block; /* Add this line */
  width: fit-content;
  padding-right: 5px
}

.info {
  display: inline-block;
  width: fit-content;
}

.info:nth-last-child(-n+2) {
  width: 150px;
  float: right; /* Add this line */
}
@media (max-width: 470px) {
  .info:last-child {
    display: none;
  }
}


.row .column:first-child {
  width: 10px; /* Set the width as per your requirement */
  align-self: flex-start; /* Aligns the column to the start of the container */
}

.draggable-item:hover {
  background: #E0E0E0; /* darker background on hover */
}

.draggable-item.is-dragging {
  background: #E0E0E0; /* darker background on hover */
}

.droppable-area {
  background: darkgray;
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.droppable-area.is-dragging-over {
  background: rgba(157, 157, 157, 0.33);
}

/* Add these new styles */
.draggable-item > div {
  flex: 1;
  text-align: center;
}

.droppable-area > .draggable-item:first-child {
  font-weight: bold;
  text-transform: uppercase;
}

.appbar  {
  height: 90px;
  position: sticky;
  elevation: 0;
  border-bottom: 1px solid;
  padding-bottom: 8px;
  @media (max-width: 600px) {
    height: 50px;
  }
}
.appbar-title .css-1ekozhk-MuiTypography-root{
  flex: 1;
  text-align: center;
  font-size: 4vw; /* Adjust as needed */
  width: 100%;
    @media (max-width: 600px) {
        font-size: 6vw;
    }
}

.appbar-logo{
  height: 90px;
  margin-right: 8px;
  @media (max-width: 600px) {
    height: 50px;
  }
}
